
var MethodPayment = (function() {

    return function MethodPayment(module = '') {
        
        
        var method_payment = [
            {value:'008', text:'EFECTIVO'},
            {value:'901', text:'DEPÓSITO BCP'},
            {value:'902', text:'DEPÓSITO BN'},
            // {value:'007', text:'CHEQUES'},
            // {value:'001', text:'DEPÓSITO EN CUENTA'},
            // {value:'003', text:'TRANSFERENCIA DEK FONDOS'},
        ]
        return method_payment;
    };

})();

export default {MethodPayment}
